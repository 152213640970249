.reset-countdown-display {
    position: absolute;
    bottom: 25px;
    right: 175px;
    background-color: rgb(238, 238, 238);
    color: #919191;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 8px;
    z-index: 11000;
    opacity: 1;
}
