body {
    background-color: #f2f6fc !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 0.9rem !important;
    color: #2b2b2b !important;
    line-height: 1.5 !important;
    font-weight: 400 !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

.bg-light {
    background-color: #ffffff !important;
}

button,
a,
link,
input,
select,
textarea {
    border-radius: 0px !important;
}

.table > :not(:first-child) {
    border-top: 0 !important;
}

.py-1.footer {
    position: fixed;
    bottom: 0;
    font-size: 12px;
}

.footer-copyrights {
    float: left;
    width: auto;
    color: black;
    padding: 5px;
}

.footer-developed-by {
    float: right;
    width: auto;
    font-size: 11px;
    color: black;
    padding: 5px;
}

h3.home-animation-h3 {
    font-family: "Montserrat Medium";
    max-width: 40ch;
    text-align: center;
    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

@keyframes scale {
    100% {
        transform: scale(1);
    }
}

span.home-animation-span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
    margin: 0px 5px 0px 5px;
}

span.home-animation-span:nth-child(1) {
    animation: fade-in 2s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span.home-animation-span:nth-child(2) {
    animation: fade-in 2s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

/*span.home-animation-span:nth-child(3) {*/
/*  animation: fade-in 2s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);*/
/*}*/

/*span.home-animation-span:nth-child(4) {*/
/*  animation: fade-in 2s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);*/
/*}*/

@keyframes fade-in {
    100% {
        opacity: 1;
        filter: blur(0);
    }
}

.edit-grid-view .empty-values {
    background: #f6ffd6;
}
.action-icons-img{
    width: 25px;
    height: 25px;
}
.action-icons-img:hover{
    backdrop-filter: gray;
}
.dashboard-main-icon img {
    max-height: 150px;
    max-width: 150px;
    width: 100%;
    height: auto;
}

.dashboard-main-icon-block {
    border: 1px solid #cecece;
    padding: 40px 20px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.dashboard-main-icon-title {
    padding: 5px;
    margin-top: 20px;
}

/* Hover effect */
.dashboard-main-icon-block:hover {
    background: #ecf7a9;
}

.pagination-bottom-block {
    margin-bottom: 60px;
    background: #fff;
    padding: 5px 10px;
    margin-top: 5px;
}
.Toastify__toast-container--top-right {
    top: 25% !important;
    right: 0 !important;
    left: 0 !important;
    margin: auto;
}

.form-group-title {
    border-bottom: 1px solid rgb(128, 128, 128);
    padding: 5px 0px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.admin-list-title-icon {
    max-width: 40px;
    padding-bottom: 8px;
    opacity: 0.5;
}

.edit-rules-icon {
    opacity: 0.2;
}
.rule-entry-group {
    width: 100%;
    clear: both;
    padding: 10px 0px;
    float:left;
}
.list-item-thumbnail img {
    max-width: 120px;
}

.table-sm tbody tr td {
    vertical-align: middle;
}

td.outdated-connection {
    background: #ffffab;
}

.border.border-secondary.p-2 {
    float: left;
}

.roleLabel {
    font-weight: 700;
    padding: 10px 0px;
    font-size: 20px;
}

.col-auto.full-search {
    width: 100%;
}

.file-input-container {
    margin: 10px 0px;
    margin-bottom: 10px;
    margin-bottom: 10px;
    float: left;
    width: auto;
    border: 1px solid #d7d7d7;
    padding: 20px;
}

.file-input-upload {
    float: left;
    width: 50%;
    clear: left;
}

.forgot-password-icon-block {
    text-align: center;
}

.forgot-password-icon {
    width: auto;
    margin: 25px;
}
.add-new-entry-button.is-disabled{
    background: gray !important;
    text-decoration: line-through;
}

.add-new-entry-button, .btn.add-new-entry-button.align-self-end, .btn.add-new-entry-button.align-self-start {
    background: #689E4B;
    border: 0px dotted #689E4B;
    color: white;
    font-weight: 600;
    padding: 10px;
    font-size: 13px;
    margin-bottom: 10px;
    border-radius: 0px !important;
}

.add-new-entry-button:hover, .btn.add-new-entry-button.align-self-end:hover, .btn.add-new-entry-button.align-self-start:hover {
    background: black;
}
.form-group-entry-rule .form-control {
    width: 90%;
}
.form-group-entry-rule {
    float: left;
    width: 25%;
}
.form-group-entry-rule label {
    font-weight: 700;
    padding: 5px 0px;
}
.form-group-entry-rule-check-box {
    text-align: center;
    margin-top: 25px;
}
.form-group-entry-rule-check-box .form-check-input {
    width: 30px;
    height: 30px;
    margin: 10px 0px 0px 20px;
}
.price-entry-input-set{
    font-weight: 700;
    font-size: 17px;
}
.price-entry-original {
    font-weight: 700;
    padding: 0px 10px;
    font-size: 17px;
    color: blue;
}
.price-entry-changed {
    font-weight: 700;
    padding: 0px 10px;
    font-size: 17px;
    color: green;
}
.add-price-rule-button {
    margin: 10px 0px;
}
.rule-entry {
    float: left;
    width: 100%;
    position: relative;
}
.delete-price-rule-button{
    margin-top: 10px;
}
.rule-entry-block {
    padding: 10px 0px;
    border-bottom: 2px solid #bbb;
    float: left;
    width: 100%;
}
.form-group-entry-rule.rule-delete-button {
    clear: both;
    float: right;
    width: auto;
    position: absolute;
    right: 10px;
    top: 10px;
}
.form-group-entry-rule.rule-enable-button {
    clear: both;
    float: right;
}
.full-width-pagination .text-end {
    width: 100%;
}

.fw-light.text-muted.text-center.mb-3 {
    margin-top: 20px !important;
    margin-bottom: 0px !important;
}

.view-list-top-bar {
    background: white;
    padding: 10px;
}

.images-container-input {
    float: left;
    width: 100%;
    clear: right;
    text-align: center;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    border: 2px solid white;
}

.images-container-input[data-operation="remove"] {
    border: 2px solid red;
}

.thumbnail-input-img {
    width: 25%;
    float: left;
    height: 150px;
    max-height: 150px;
    padding-left: 30px;
}

.thumbnail-input-img img {
    height: 100%;
}

.thumbnail-input-remove {
    float: right;
    background: none;
    border: none;
    font-weight: 700;
    color: red;
    padding: 20px;
    width: 25%;
    font-size: 16px;
}

.thumbnail-input-remove:hover {
    color: black;
    font-size: 20px;
}

.images-container-input[data-operation="remove"] .thumbnail-input-remove {
    font-size: 40px;
}

.thumbnail-container-input {
    width: 50%;
    text-align: center;
    display: inline;
    padding: 0px 100px;
}

.thumbnail-file-input-add {
    float: left;
    background: #e1e1e1;
    font-weight: 700;
    color: black;
    padding: 10px;
    width: 100%;
    border: 0px;
    margin: 0px 0px 30px;
}

.order-td[data-status="COMPLETED"] {
    color: green;
    font-weight: 700;
}

td.category-list-thumbnail img {
    max-width: 100px;
}

td.product-list-thumbnail img {
    max-width: 100px;
}

td.brand-list-thumbnail img {
    max-width: 100px;
}

.dropdown-item.logout-menu-item {
    color: #240101;
    background: #ececec;
    font-weight: 700;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
    box-shadow: 1px 2px 4px gray;
}

.btn-close-popup-admin {
    background: unset;
    border: unset;
    color: #5e5e5e;
    font-size: 17px;
}

.message-master {
    width: 100%;
    left: 0;
}

.message-master .toast.show {
    margin: auto;
}

.message-admin-master {
    background: #bdbdbd75;
}

.admin-toast {
    padding: 30px;
}

.total-stock-machine {
    font-weight: 500;
    color: red;
    cursor: pointer;
}

.fetch-total-stock {
    font-weight: 500;
    cursor: progress;
}

.total-price-machine {
    font-weight: 500;
    color: red;
    cursor: pointer;
}

.fetch-total-price {
    font-weight: 500;
    cursor: progress;
}

.refetch-icon {
    max-width: 30px;
}

.modal-dialog {
    max-width: 1300px;
}

.modal-dialog .modal-body .mb-3 {
    float: left;
    width: 100%;
}

.main-user-error-message-block {
    width: 100%;
    text-align: center;
    margin-top: 50px;
}

.not-found-page-container {
    width: 100%;
    text-align: center;
    margin-top: 100px;
}

.table-sm > :not(caption) > * > * {
    text-align: center;
}

#machineModal .modal-dialog {
    max-width: 1600px;
}

.language-selector {
    position: relative;
    width: 100px;
    cursor: pointer;
    right: 0;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .navbar-collapse .nav-item.dropdown {
        text-align: center;
    }

    .navbar-collapse .nav-item.dropdown .dropdown-item {
        text-align: center;
    }

    .navbar-collapse .nav-item.dropdown .dropdown-menu .dropdown-item {
        background: #e6e6e6;
        margin: 0px 0px 5px;
    }
}
@media (max-width: 576px) {
    .dashboard-main-icon-block {
        padding: 20px 10px;
    }
}
