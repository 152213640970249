.entries-block-box-button {
    float: right;
    background: #373737;
    color: white;
    font-size: 15px;
    font-weight: 700;
    padding: 5px 10px;
    margin: 0px 0px 50px;
    width: 100%;
}

.entries-block-box {
    float: left;
    width: 100%;
    margin: 20px 0px;
}

.entry-add-button {
    border: 1px solid gray;
    border-radius: 20px !important;
    width: 120px;
    float: left;
    margin: 10px 0px 0px;
}

.entry-delete-button {
    background: #ff8c8c;
    padding: 0px 5px;
    border-radius: 5px !important;
    border: 1px solid gray;
    font-weight: 500;
    width: 100%;
    font-size: 13px;
    text-align: center;
    margin-top: 6px;
}

.entry-inputs-block {
    margin: 5px 0px 5px;
    float: left;
    border: 1px solid gray;
    padding: 10px 5px;
    background: #c7ffbf;
    width: 7.13%;
    position: relative;
    height: 400px;
}

.entries-input-rows {
    display: none;
}

.entry-delete-button {
    display: none;
    float: left;
    position: relative;
}

.entry-inputs-block[data-placeholder="true"] {
    background: #dde4e9;
}

/*.entry-inputs-block[data-merge="true"] .entry-delete-button,*/
/*.entry-inputs-block[data-merge="true"] .list-item-input-thumbnail,*/
/*.entry-inputs-block[data-merge="true"] .upc-entry-input-dropdown,*/
/*.entry-inputs-block[data-merge="true"] .entry-input-row-data {*/
/*    display: none;*/
/*}*/



/*.entry-inputs-block[data-merge="true"] .entry-input-merge {*/
/*    display: block;*/
/*    margin: 40px 0px;*/
/*}*/
.entry-inputs-block[data-merge="true"]{
    background-color: #b2cef9;
}

.entry-inputs-block[data-merge="true"] .entry-input-merge input {
    background-color: #0d6efd;
    border-color: #0d6efd;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}

.entries-block-box.maximize_mode .entry-inputs-block {
    width: 14.2%;
    padding: 10px 10px;
}

.entries-block-box .entry-inputs-block {
    height: 120px;
}

.entries-block-box.maximize_mode .entry-inputs-block {
    height: 170px;
}

.entries-block-box.details_mode .entry-inputs-block {
    height: 540px;
}

.entries-block-box.details_mode.maximize_mode .entry-inputs-block {
    height: 450px;
}

.entries-row-group.show-row-details .entry-inputs-block {
    height: 540px;
}
.entries-row-group.show-row-details .entries-input-rows{
    display: block;
}
.entries-row-group.show-row-details .entry-input-merge{
    display: block;
}
.entries-row-group.show-row-details .entry-delete-button{
    display: block;
}
.entries-row-group.show-row-details .entries-row-title::after {
    content: '=';
    height: 2px;
    right: 22px;
    color: white;
    text-shadow: 1px 1px 1px black;
    cursor: pointer;
    position: absolute;
    font-size: 35px;
    line-height: 30px;
}

.entries-block-box.maximize_mode .upc-entry-input-dropdown input {
    font-size: 16px;
    font-weight: 700;
}

.entries-block-box.maximize_mode .entry-delete-button {
    font-weight: 700;
}

.entries-block-box.details_mode .entries-input-rows,
.entries-block-box.details_mode .entry-delete-button {
    display: block;
}

.planogram-style-actions {
    position: relative;
    text-align: right;
}

.planogram-style-button {
    background: #dde4e9;
    padding: 5px 10px;
    font-weight: 700;
    margin: 0px 5px;
    border: 1px solid gray;
}

.details_mode .planogram-style-button.details,
.maximize_mode .planogram-style-button.maximize {
    background: #484848;
    color: white;
}

.maximize_mode .entry-input-row-data input {
    max-width: 50%;
    width: 50%;
}

.maximize_mode .entry-input-row-data-title {
    width: 50%;
    max-width: 50%;
    font-size: 15px;
}

.maximize_mode .entry-input-merge .entry-input-row-data-title {
    width: 50%;
    max-width: 50%;
}

.entry-input-merge {
    position: relative;
    float: left;
    width: 100%;
}

.entry-input-merge {
    display: none;
}

.details_mode .entry-input-merge {
    display: block;
}

.maximize_mode .entry-input-merge input {
    width: 50%;
    max-width: 50%;
    padding: 10px;
}

.entries-row-title {
    float: left;
    width: 100%;
    color: white;
    font-weight: 700;
    padding: 5px 15px;
    background: #484848;
    font-size: 20px;
    cursor:pointer;
}

.entry-input-row-data {
    float: left;
    width: 100%;
}

.entry-inputs-block.problematic-data {
    background: #ffd7d7;
}

.entry-inputs-block.no-stock {
    background: #ffff80;
}

.entry-input-row-data-title {
    font-weight: 600;
    padding: 5px 0px 0px;
    width: 100%;
    max-width: 100%;
    float: left;
    font-size: 14px;
}

.entries-block-box input {
    max-width: 100%;
    width: 100%;
    border: 1px solid gray;
}

.upc-entry-input-dropdown {
    width: 100%;
    float: left;
    max-width: 100%;
    margin-bottom: 10px;
}

.upc-entry-input-dropdown input {
    max-width: 100%;
    font-weight: 500;
    width: 100%;
    font-size: 13px;
    padding: 0px 2px;
    border: none;
    border-top: 1px solid gray;
}

.upc-entry-input-select-dropdown {
    font-weight: 400;
    font-size: 14px;
    max-width: 100%;
}

.upc-entry-lane-number {
    position: absolute;
    font-weight: 700;
    top: 5px;
    right: 10px;
    text-shadow: 1px 1px 1px black;
    color: red;
}

.entry-add-button {
    border: 1px solid #a8a8a8;
    border-radius: 10px !important;
    width: 120px;
    padding: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 50px;
    margin: auto;
    left: 0;
    right: 0;
}

.new-entries-block-box {
    margin: 0px 0px 10px;
    float: left;
    width: 12%;
    text-align: center;
    padding: 50px;
    position: relative;
}

.list-item-input-thumbnail {
    background: white;
    text-align: center;
    height: 75px;
}

.maximize_mode .list-item-input-thumbnail {
    height: 125px;
}

.list-item-input-thumbnail img {
    max-width: 100%;
    height: 100%;
    width: auto;
    cursor: pointer;
}

.entries-stock-group {
    float: left;
    position: relative;
    border-bottom: 5px dotted gray;
    padding-bottom: 25px;
    clear: both;
}

.entries-stock-title {
    font-size: 30px;
    font-weight: 700;
    padding: 10px 5px;
    position: relative;
    cursor: pointer;
}

.entries-row-group {
    position: relative;
    float: left;
    width: 100%;
}

.entry-input-row-data.readonly-data {
    display: none;
}

.entries-row-group.hide-row-details{

}


.entries-stock-group.hide-stock-rows .entries-stock-rows {
    display: none;
}

.entries-stock-group.hide-stock-rows .entries-stock-title::after {
    content: '+';
    width: 100%;
    height: 2px;
    right: -15px;
    position: relative;
    color: green;
    text-shadow: 1px 1px 1px black;
    cursor: pointer;
}

input.search-item-input {
    position: relative;
    width: 80%;
    background: white;
    color: black;
    padding: 8px 10px;
    font-weight: 700;
    border: 2px solid gray;
    font-size: 25px;
}

button.entry-edit-modal-close {
    width: 20%;
    padding: 10px;
    font-weight: 700;
    font-size: 25px;
    float: right;
    cursor: pointer;
    border: unset;
}

button.entry-edit-modal-close:hover {
    background: black;
    color: #ffffff;
}

li.search-item-data {
    background: #e5e5e5;
    cursor: pointer;
    margin: 5px 0px;
    padding: 5px;
    font-weight: 700;
}

li.search-item-data:hover {
    background: black;
    color: #ffffff;
}

.entry-edit-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.entry-edit-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 700px;
    max-height: 100%;
    overflow-y: scroll;
}

@media only screen and (max-width: 740px) {

}