.checkout-countdown-display {
    position: fixed;
    right: 26px;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 30px;
    z-index: 1000;
    margin: auto;
    width: 100px;
    height: 100px;
    font-weight: 600;
    color: #d7d7d7;
    bottom: 0;
}
